
































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_home, Data, OrderDataReturn } from "@/http/home";
import { api_store, store } from "@/http/store";
import DatePicker from "@/components/datePicker/index.vue";
@Component({
  components: {
    DatePicker,
  },
})
export default class extends Vue {
  data: Data = {
    store_num: 0,
    wait_wash_num: 0,
    wait_taken_num: 0,
    order_num: 0,
    put_num: 0,
  };
  orderDataReturn: OrderDataReturn = {
    order_data: 0,
    turnover: "",
  };
  filterForm = {
    type: 1,
    start_time: "",
    end_time: "",
    store_id: "",
  };
  goToPut() {
    this.$router.push("/warehouse/put");
  }
  async getData() {
    try {
      const response = await api_home.get_data();
      this.data = { ...response };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  storeData: any = [];
  // 获取门店数据
  getStoreData() {
    api_store
      .get_list({
        page: 1,
        page_size: 99999,
      })
      .then((res) => {
        this.storeData = res.results;
        this.storeData.unshift({
          id: "",
          name: "全部门店",
        });
      });
  }
  typeList = [
    { value: 1, label: "今日" },
    { value: 2, label: "7天" },
    { value: 3, label: "30天" },
    { value: 4, label: "自定义" },
  ];
  // 获取数据概览
  async getOrderData() {
    try {
      const response = await api_home.get_order_data(this.filterForm);
      console.log("订单数据", response);
      this.orderDataReturn = { ...response };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  // 监听状态
  @Watch("filterForm.store_id")
  ChangeStore(val: number) {
    this.getOrderData();
  }
  changeType(type: number) {
    this.filterForm.type = type;
    if (type !== 4) {
      this.getOrderData();
      this.filterForm.start_time = "";
      this.filterForm.end_time = "";
    }
  }
  update_time: [Date, Date] | null = null;
  onDateRangeChange(selectedDates: [Date, Date]) {
    this.update_time = selectedDates;
    this.filterForm.start_time = this.formatDateToString(selectedDates[0]);
    this.filterForm.end_time = this.formatDateToString(selectedDates[1]);
    this.getOrderData();
  }
  formatDateToString(dateString: Date) {
    // 创建一个新的Date对象
    var dateObject = new Date(dateString);

    // 提取年、月、日并格式化为"YYYY-MM-DD"
    var year = dateObject.getFullYear();
    var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    var day = ("0" + dateObject.getDate()).slice(-2);
    // 返回格式化后的日期时间字符串 "YYYY-MM-DD HH:mm:ss"
    return `${year}-${month}-${day}`;
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filterForm.start_time = startStr;
    this.filterForm.end_time = endStr;
    this.getOrderData();
  }
  created() {
    this.getData();
    this.getStoreData();
    this.getOrderData();
  }
}
