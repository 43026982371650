
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface Data {
    store_num: number;
    wait_wash_num: number;
    wait_taken_num: number;
    order_num: number;
    put_num: number;
}
export interface OrderData {
    type: number;
    start_time: string;
    end_time: string;
    store_id: number|string;
}
export interface OrderDataReturn {
    order_data: number;
    turnover: string;
}

class HttpHome extends Http {
    get_data = () => {
        return this.get<Data>({}, `/admin/index/`);
    }
    get_order_data = (data: OrderData) => {
        return this.get<OrderDataReturn>(data, `/admin/data/`);
    }
}

export const api_home=  new HttpHome('')
